import "../../styles/articles.css"
// import logo from 'img/deepbird/policy_pi1.gif'
import Latex from 'react-latex';

const deepbird = props => {


  const equation1 = ` $$\\pi(A_t = a | S_t = s)$$`;
  const equation2 = ` $$V_{\\pi}(s) = E[G_t | S_t = s]$$`;
  const equation3 = ` $$G_t = \\Sigma_{k=0}^{\\infty}\\gamma^k R_{t+k+1}$$`;
  const equation4 = `$$\\gamma$$`;
  const equation5 = `$$\\pi$$`;
  const equation6 = ` $$\\alpha$$`;
  const equation7 = ` $$V_{\\pi}(s)$$`;
  const equation8 = ` $$Q_{\\pi}(s, a)$$`;

  return (
  <div class="container-fluid mb-3 mt-5" id="nib">
    <div id="spacer">A</div>

    <h2>DeepBird</h2>
    <p><small>May 6, 2024</small></p>
    <div class='row mb-3'>
      <img src="/img/deepbird/deepbird_thumbnail.png" alt="" width='1200' height='500'></img>
    </div>

    <h4 class="mt-2">Project Overview</h4>

    <div class="row">
      <p>
        Deepbird is my attempt at building an AI to play Flappybird. Many other projects that I've seen online used genetic AI, but here we
        use reinforcement learning . In fact, Deepbird started as an exercise to gain a strong understanding of the basics of reinforcement learning.
        After reading the first part of <a href="http://incompleteideas.net/book/RLbook2020.pdf"> Sutton and Barto</a>, I wanted
        to actually implement some of the algorithms mentioned and tackle the problems that inevitably arise when putting theory into 
        practice.
      </p>
    </div>

    <div class="row">
      <p> 
        All code can be found in this <a href="https://github.com/JacobAlbus/deepbird/tree/main">github repo</a>. 
        The executable can be found in the x64/debug folder, or you can have the AI play the game by calling the "tkinter_app.py" 
        python script. I recreated FlappyBird using a game engine which I coded from scratch using a mix of OpenGl and SDL. 
        Development was done on Visual Studio 2022 and since this repo includes the header, lib, and dll files for 
        the SDL and OpenGl libraries, any system with a C++ compiler should be able to compile the source code.
        </p>
    </div>

    <h4 class="mt-2">What is Reinforcement Learning?</h4>
    <div class="row">
      <p>Reinforcement learning (RL) is one of three major paradigms in machine learning (ML), along with supervised 
        learning and unsupervised learning. In supervised learning the goal is to use labeled data to build a model that
         predicts the labels of unlabeled data. In unsupervised learning the data is unlabeled so the goal instead is to 
         find hidden structures or patterns in the data. In RL the data comes from an agent-environment 
         interaction and the goal is for the agent to maximize the reward from the environment. Essentially, the use cases 
         for these paradigms come down to what data you have and how it's being generated.
      </p>
    </div>

    <div class="row">
      <p>
      Furthermore, the agent-environment interaction can be formalized using a <a href="https://en.wikipedia.org/wiki/Markov_decision_process">markov decision process</a> 
       (see image below). 
      At each time step the agent observes the state of the environment and uses it to determine what action should be taken. This 
      causes the environment to update to a new (possibly different) state, which in turn causes the agent to take another 
      action. This process can repeat forever (the non-episodic case) or until a terminal state is reached (the episodic case). 
      Also, each time the agent observes a state it also receives a reward from the environment. This reward signal is the 
      driving force behind training in RL.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/mdp.png" alt="" id="small-img" width='500' height='350'></img>
    </div>

    <div class="row">
      <p>
      In this sense, RL combines the strengths of supervised and unsupervised learning. It has a clear objective 
      (maximize the reward signal) just like supervised learning (attain high prediction accuracy), but doesn't 
      require labeled data à la unsupervised learning. Further, the agent is never told explicitly which actions to take as
       opposed to supervised learning where correct labels (actions) are given. Instead the agent learns which actions 
       to take by learning which actions (given the current state) will lead to high reward. 
      </p>
    </div>

    <div id="caption">
      <p>
      Note: designing a reward function can be tricky and is very task specific. Only through trial-and-error and domain 
      knowledge can a viable reward function be formulated. We will later go through the many iterations I went through for 
      FlappyBird.
      </p>
    </div>

    
    <h4 class="mt-2">Policies and Value Functions</h4>

    <div class="row">
      <p>
      A policy defines the behavior of the agent and is typically defined as a conditional probability function written as  
      <Latex>{equation1}</Latex> where 't' is the current timestep. In other words, the policy says what percentage of 
      the time should action 'a' be taken given that we're currently at state 's'. Sometimes the policy is deterministic, 
      which is to say that for a state 's' 100% of the probability is assigned to a single action 'a' and 0% to all other 
      actions. Thus, a deterministic policy selects the same action each time it visits state 's'. 
      </p>
    </div>

    <div class="row">
      <p>
      As mentioned before the goal is to maximize the reward signal so we need a way to evaluate how “good” a policy is and 
      evaluate it against other policies. So we define the value function <Latex>{equation2}</Latex> where <Latex>{equation3}</Latex> 
      is the discounted sum of rewards. The "discount" comes from the fact that <Latex>{equation4}</Latex> is between 0 and 1 
      (exclusive), thus giving more weight to rewards that occur closer to the current timestep. Essentially, the value function asks
      "how much reward do we expect after reaching state 's' at timestep 't' and follow policy <Latex>{equation5}</Latex> thereafter?". 
      Further, by doing some definition  chasing we get the bellman equation (the bottom line in the below image). 
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/bellman.png" alt="" id="med-img" width='500' height='250'></img>
    </div>

    <div class="row">
      <p>
      The bellman equation can be better understood using the below "backup diagram". 
      Starting from a state s (the white root of the tree), we can take a number of actions according to the policy (the black nodes), 
      which then lead to a number of successor states s'. Each of these successor states is reached with a probability according to 
      the policy and the dynamics of the environment. In this case the environment dynamics are represented by the conditional probability 
      function p(s', r | s, a), which defines the probability of going to state "s'" given we take action "a" at state "s". 
      Thus the bellman equation is just a weighted sum of the value functions for the successor states and their associated reward. 
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/backup_diagram.png" alt="" id="small-img" width='300' height='300'></img>
    </div>

    <h4 class="mt-2">Algorithms</h4>
    <div class="row">
      <p>Currently only two methods have been implemented: Policy Iteration and Sarsa. Initially I implemented Policy Iteration as a proof of
        concept since it's the most basic RL algorithm and was the fastest to train (since we have a perfect model of the environment). I 
        then implemented Sarsa to prove that flappybird could be learned using model-free methods with no deep learning involed.
      </p>
    </div>

    <h4 class="mt-2">Policy Iteration</h4>

    <div class="row">
      <p>
      The collection of value functions defined at each state creates a system of linear equations, which gives us a straightforward way 
      of computing the value functions. But remember that solving a linear system takes O(n^3) time so it's actually quite slow. 
      It turns out that another way to solve for the value functions is simply by repeatedly applying the Bellman Equation as an 
      update rule. This leads to the below algorithm, called policy evaluation, which only approximates the true value function
      (but that's good enough).
      </p> 
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/policyevaluation.png" alt="" id="med-img" width='500' height='200'></img>
    </div>

    <div class="row">
      <p>
      Then once we have our (approximated) value function we can update our policy to take actions that bring us to states with greater 
      expected sum of reward. This algorithm is called policy improvement. Note that the policy here is deterministic, hence why 
      the update only weights the value functions with the transition probabilities and not the action probabilities.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/policyimprovement.png" alt="" id="med-img" width='500' height='200'></img>
    </div>

    <div class="row">
      <p> Finally, we can alternate between these two steps (policy evaluation and policy improvement) to get our first algorithm: 
        policy iteration.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/policy_iteration.png" alt="" id="med-img" width='500' height='500'></img>
    </div>

    <div id="caption">
      <p>
      Note: Derivations and proofs of the above algorithms can be found in Sutton and Barto chapters 3 and 4.
      </p>
    </div>

    <h4 class="mt-2">Policy Iteration Implementation and Results</h4>

    <div class="row">
      <p>The height range of the player is 960 pixels, the height range of the pipes is 360 pixels, and the maximum distance of the 
        player from the pipes is 480 pixels. If we divide the player height range into 8 pixel intervals, the pipe height range into 4 pixel
        intervals, and the player distance into 8 pixel intervals, then we represent our states as 3d tuples which ranges from (0, 0, 0) to
        (119, 89, 59). Note that we don't include the player velocity as part of the state since it turns out to not be needed. 
        Our action space is just 1 or 0, where 1 is "do nothing" and 0 is "jump". Since the environment is deterministic we define the below
        function to give us the next state given the current state and action. Further, since we don't include the player velocity,
        we assume that not jumping decreases player height by 3 and jumping increases player height by 1.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/pi_next_state.png" alt="" id="small-img" width='500' height='200'></img>
    </div>

    <div class="row">
      <p>With our state and action space defined, the only thing left to do is to define the reward function. As previously mentioned
        I went through a handful of iterations. The first reward function gave equal reward when the bird was in between the two pipes, 
        and at no other points. The below image shows a heatmap of the value functions and associated policy when the height is kept
        constant at 40. Notice how the exepcted reward is 0 outside of this "cone"; this came about as a quirk of the game dynamics/physics.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/pi_reward_function1.png" alt="" width='800' height='600'></img>
    </div>

    <div class="row">
      <p>If you look at the yellow section (which corresponds to the pipe opening), you'll notice that the color is uniform meaning
        that the expected reward is equal in that area. Similarly, the vertical columns to its right are also all uniform. Further,
        since the policy improvement step will choose the "jump" action if both action lead to equal expected reward, then the 
        policy chooses to jump everywhere except the top edge of the value function. This can be seen in the below gif of this AI
        playing flappybird. The bird just keeps jumping without stopping!
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/pi_policy1.gif" alt="" width='800' height='600'></img>
    </div>

    <div id="caption">
      <p>
      Note: You may be wondering why the sliver of "do nothing" actions doesn't stop the bird from hitting the ceiling. Well,
      that's because at each frame if the bird is jumping, it's velocity will jump to 5 but if it does nothing then it will only
      decrease by 0.166667 each frame. Thus, doing nothing for a handful of frames won't cause the bird's vertical position to decrease.
      </p>
    </div>

    <div class="row">
      <p>For the second reward function, I watned to avoid the bird flying to the top. 
        Thus, I give maximum reward when the player is parallel to the pipe opening and decrease the reward
        as the player height goes below the top part of the bottom pipe or above the bottom part of the top pipe.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/pi_reward_function2.png" alt="" width='800' height='600'></img>
    </div>

    <div class="row">
      <p>While I was successful at preventing the bird from flying to the top, the value function is still the same for when the bird
        is parallel to the pipe opening. Thus, it only takes the "do nothing" action for a small sliver of the area when the bird
        is parallel to the pipe opening. In the below gif we see the bird jump up to this sliver then hover around it as it only
        jumps when it goes below this sliver.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/pi_policy2.gif" alt="" width='800' height='600'></img>
    </div>

    <div class="row">
      <p>For the third reward function I wanted to ensure that no two states sharing the same player height value should
        have the same expected sum of rewards. Thus the reward function here gives full reward when the bird is at the midpoint
        of the pipe opening, and this reward decreases linearly as the distance from this midpoint increases. The below image shows
        this midpoint line.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/reward_function1.png" alt="" id="small-img" width='800' height='600'></img>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/pi_reward_function3.png" alt="" width='800' height='600'></img>
    </div>

    <div class="row">
      <p>Notice how we see some green-yellow gradient even in the pipe opening area. This indicates that the value function isn't uniform anywhere,
        so the AI is able to learn which actions will lead to states that have a higher value function! We confirm this with the below gif
        showing the AI playing the game perfectly by staying at the midpoint of the pipe openings.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/pi_policy3.gif" alt="" width='800' height='600'></img>
    </div>

    <h4 class="mt-2">Problems with Policy Iteration</h4>

    <div class="row">
      <p>
      While policy iteration works well in our case, it kinda feels like cheating since the agent never trained or learned on the
      actual environment it would be playing with. Instead it used a model of the environment. Further there are two major disadvantages
      with Policy Iteration that come up in other tasks. Firstly it requires a perfect model, 
      which we might not have. Secondly it requires a complete sweep of the state space which could quite literally be impossible for 
      games like Chess or Go where there are more states than atoms in the universe. These two problems can be alleviated with a model-free
      method that samples from the environment itself (instead of a model of the environment). The former problem is clearly solved with
      a sampling method (since we don't use a model of the environment), and the second problem is solved since sampling methods tend to
      only sample states which are "relevant". It often turns out that a small fraction of the total state space is "relevant". 
      We will explore temporal difference methods in the next section as one such model free method.
      </p>
    </div>

    <div class="row">
      <p>
      Despite its shortfalls, policy iteration is still hugely relevant to more sophisticated and modern methods. The basic idea of
      evaluating then improving then evaluating then improving a policy is the foundation of just about every RL
      algorithm. In fact, this idea is called "Generalized Policy Iteration" and can be understood geometrically with the below image. 
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/gpi.png" alt="" id="small-img" width='500' height='300'></img>
    </div>

    <div>
      <p>
        The real geometry is more complex (and much higher dimensional), but the main idea is the same. After you evaluate a policy and
        find the value function it induces, the policy is no longer optimal with respect to the value function. But then updating the 
        policy to be optimal with respect to the value function makes the previously calculated value function inaccurate, necessitating
        another round of policy evaluation. This process continues until the policy and value function converge to their optimal values, 
        which in the case of tabular tasks (i.e. where the state space is finite) have been proven to exist. 
      </p>
    </div>

    <h4 class="mt-2">Temporal Difference</h4>

    <div class="row">
      <p>
      The most obvious choice for updating the value function via sampling would be to use some sort of monte-carlo method. This is to say,
      sample a trajectory (e.g. play the game until the bird dies), calculate the sum of rewards at each timestep, then update the value
      function at each state to be closer to the calculated sum of rewards at the respective timestep. However, this has two problems: 
      firstly we have to wait for the episode to be finished before we can update and secondly we can't use estimates from other states 
      to inform our current estimates (i.e we can't bootstrap). This second point is of note since it means that when we encounter a state
      for the first time, we can't use information we've gained from nearby states (i.e. we have to start from scratch). We can remedy
      both of these issues by using the idea of <p id="caption">temporal difference.</p>  
      </p>
    </div>

    <div class="row">
      <p>
      In temporal difference, we can update while we sample a trajectory instead of only after we finish sampling. This way, we push our
      current estimate for our current state towards a more refined target estimate. This target estimate is more accurate than our current
      estimate because it incorporates the reward we get at the next time step. The below image shows this new update rule.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/td_update_notes.png" alt="" width='500' height='300'></img>
    </div>

    <div id="caption">
      <p>
      Here <Latex>{equation6}</Latex> is the learning rate (from 0 to 1) and controls the step size we take when we update towards our 
      target estimate. A more concrete and thorough explanation of temporal difference can be found in Example 6.1 of Sutton and Barto.   
      </p>
    </div>

    <div class="row">
      <p>
        However, there's a problem: since we no longer have a model of the environment, the previous policy improvement algorithm won't 
        work because we don't know which actions will lead to which states. To this end, I introduce an action value function 
        <Latex>{equation8}</Latex> which is the expected sum of rewards conditioned on the state "s" AND the action "a". The previously
        introduced value function <Latex>{equation7}</Latex> is the expected sum of rewards conditioned ONLY on the state "s".
      </p>
    </div>


    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/q_function.png" id="med-small-img" alt="" width='800' height='70'></img>
    </div>

    <div class="row">
      <p>
        Then we define a policy such that, given the current state, take the action that has the highest expected sum of rewards. 
        This is what's known as a greedy policy since it takes the action that maximizes the reward for the CURRENT timestep without
        considering possible future states. However, greedy policies often end up finding a suboptimal action early on and never finding
        the optimal action. To this end, we encourage exploration through an epsilon greedy policy which forces the agent to Sometimes
        take a random action.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/epsilon_greedy.png" id="med-img" alt="" width='800' height='200'></img>
    </div>

    <div id="caption">
      <p>
        Note: The above code shows the epsilon greedy policy used in my code. Here, '0' corresponds to a "jump" action and '1' corresponds
        to be a "do nothing" action. Further, epsilon is a number between 0 and 1 and is usually set to 0.1 when training starts and is slowly
        decreased to 0 as training progresses.
      </p>
    </div>

    <h4 class="mt-2">Sarsa Implementation and Results</h4>

    <div class="row">
      <p>A popular temporal difference algorithm is the Sarsa algorithm (named as such because it uses the state and action of the current
        timestep and the reward, state, and action of the next timestep). The pseudocode is below and relies mostly on the temporal difference
        update introduced in the previous section. We use the same action space, state space, and reward function from policy iteration.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/sarsa.png" alt="" id="med-img" width='400' height='350'></img>
    </div>

    <div class="row">
      <p>
        Below is the results of running Sarsa for 1.8 billion frames. Notice that this heatmap is much more patchy than the policy iteration
        heatmap. Remember that Sarsa relies on sampling from the state space, so it'll continue to update states that it deems as "relevant".
        For example, after so much training the bird won't be visiting the states above the bottom part of the top pipe so it won't update
        its estimates for them. So then it won't update its policy for them either, thus resulting in them remaining as "jump" actions. 
      </p>
    </div>
            
    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/sarsa1_heatmap.png" alt="" width='400' height='600'></img>
    </div>

    <div class="row">
      <p>
        However, even after 1.8 billion frames (7 days of real time training on my laptop) the bird is only able to average a score of 25.
        Further, the rate at which it was learning begain to quickly level off meaning that it would take even longer to average a score of 
        50. This is TERRIBLY slow, so I started looking for ways to improve training efficiency. 
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/sarsa_performance.png" alt="" width='400' height='550'></img>
    </div>
    
    <div class="row">
      <p>
        The previously used reward function, despite giving maximum reward at the pipe midpoint, still has positive
        reward for every state other than the terminal state. This can potentially confuse the AI when, for instance, it continually jumps
        when it's above the midpoint line and updates the respective state-action pairs to a positive number. 
        At this point the value function for "do nothing" is at 0 (since the value function at every state-action
        pair is initialized to 0), so even though the "do nothing" action would lead to higher reward, the AI will continue to think that the "jump" action will 
        action is the preferred action and choose it over "do nothing". As mentioned before we implemented an epsilon-greedy policy to remedy this,
        but with a small epsilon it may be slow to update these errors. As such, I decided that we needed a new reward function to improve training
        time. Reward is still maximized at the pipe midpoint, but now instead of slowly decreasing as distance from the midpoint increases, the
        reward decreases rapidly to 0 so that reward is only given when the bird is near the midpoint. The below image shows this.
      </p>
    </div>
      
    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/reward_function2.png"  alt="" id="small-img" width='800' height='600'></img>
    </div>

    <div>
      <p>
        This is known as a sparse reward function since most states don't receive any reward. A problem with using Sarsa with a sparse reward
        function is that when we receive a reward, only the preceeding state-action pair's action value function is updated. This can result in
        it taking a long time for the reward signal to propagate to farther away state-action pairs. Luckily, we can slightly modify Sarsa
        to overcome this slow propagation. One thing you may notice about Sarsa is that it only looks at the reward and state of the next 
        timestep instead of looking at the rewards and state over multiple timesteps. In fact, looking further ahead should give us a more 
        accurate target estimate and allows for the reward signal to more quickly propagate. This is precisely the thought process behind
        n-step Sarsa.
      </p>
    </div>

    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/n-step sarsa.png" alt="" id="med-img" width='400' height='450'></img>
    </div>

    <div id="caption">
      <p>
        Note: In my implemention I look ahead by 5 steps, resulting in 5-step Sarsa. The previously introduced Sarsa algorithm is really just 
        a special case of n-step Sarsa, specifically it would be called 1-step Sarsa
      </p>
    </div>

    <div>
      <p>
        Using this new reward signal, in conjunction with n-step Sarsa, we are able to achieve remarkable better results. After just
        400 million frames, we achieve better results than just plain Sarsa. By 1.8 billion frames we start to average a score of 100 
        (a 4x improvement!).
      </p>
    </div>


    <div class='row mb-3 mt-3'>
      <img src="/img/deepbird/sarsa_comparison.png" alt="" width='400' height='550'></img>
    </div>

    <h4 class="mt-2">Next Steps and Conclusion</h4>
    
    <div>
      <p>
        While I was able to get a 4x performance improvement, 7 days of training time is still VERY slow. Further, my goal was to train
        an AI to play the game perfectly, which is yet to happen. One area that can be improved is the fact that when the agent learns
        a path for one pipe height, it is unable to transfer the knowledge to another (possibly similar) pipe height. This is because
        the current value function is tabular and we only update the values for a single state-action pair. By using function approximation
        to estimate our value function, we can potentially transfer knowledge about one pipe height to another. Stay tuned for another
        article on how to use function approximation in RL!
      </p>
    </div>


  </div>
  );
};

export default deepbird;